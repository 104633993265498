<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <div class="col-12">
        <FormInputCheckbox not-required v-model="settings.google_merchant_allowed">Abilitare l'esportazione su Google Merchant?</FormInputCheckbox>
        <FormInputCheckbox not-required v-model="settings.feed_allowed">Abilitare l'esportazione individuale del feed?</FormInputCheckbox>
      </div>

      <FormInputTextWidget
        class="col-12"
        label="Webhook da chiamare ad ogni acquisto"
        v-model="settings.webhook"
        :errorMsg="errorHash.webhook"
        @input="removeFieldError('webhook')"
      />

      <div class="col-12 mt-3">
        <button class="btn btn-primary px-5">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import { isWebUri } from 'valid-url';

import validateMixin from '@/libs/Form/mixins/validate.js';
import rolesMixin from '@/mixins/roles.js';

import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputCheckbox from '@/libs/Form/components/Inputs/FormInputCheckbox.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';

export default {
  mixins: [validateMixin, rolesMixin],
  components: {
    FormContainer,
    FormInputCheckbox,
    FormInputTextWidget,
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (this.settings.webhook && !isWebUri(this.settings.webhook)) {
        this.addError('Il webhook non è valido', 'webhook');
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const settingsToReturn = { ...this.settings };

      this.$emit('submitForm', settingsToReturn);
    },
  },
};

</script>
