<template>
  <Page class="page-shop page-shop-settings page-shop-settings-export" v-if="settings">
    <template #title>Opzioni di esportazione</template>

    <template #title-extra>
      <router-link v-if="isEdit" class="btn btn-outline-light" :to="{ name: 'shop.settings.export' }">Annulla modifica</router-link>
      <router-link v-else class="btn btn-outline-light" :to="{ name: 'shop.settings.export.edit', params: { edit: 'edit' } }">Modifica</router-link>
    </template>

    <template #default>
      <ShopExportForm v-if="isEdit" :feedback.sync="feedback" :settings="settings" @submitForm="submit" edit />
      <template v-else>
        <p>Feed Google Merchant globale? <template v-if="settings.google_merchant_allowed">Sì</template><template v-else>No</template></p>
        <p>Feed individuale? <template v-if="settings.feed_allowed">{{ settings.feed_url }}</template><template v-else>No</template></p>
        <p>Webhook endpoint? <template v-if="settings.webhook">{{ settings.webhook }}</template><template v-else>No</template></p>
      </template>
    </template>
  </Page>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';

import Page from '@/views/components/Private/Page.vue';
import ShopExportForm from '@/views/components/Form/ShopExportSettingsForm.vue';

export default {
  components: {
    Page,
    ShopExportForm,
  },
  data: () => ({
    settings: null,
    feedback: null,
  }),
  computed: {
    isEdit () {
      return this.$route?.params?.edit;
    },
  },
  methods: {
    submit (settings) {
      this.feedback = null;

      this.$api.updateMyShopExportSettings(settings)
        .then(res => {
          this.settings = res.data;
          this.$router.replace({ name: 'shop.settings.export' });
          this.$oauth2.refreshMe();
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.feedback = null;
  },
  beforeRouteEnter (to, from, next) {
    $api.getMyShopExportSettings()
      .then(res => res.data)
      .then(data => {
        if (!data) {
          return next(from);
        }

        next(vm => {
          vm.settings = data;
        });
      })
      .catch(() => next(from))
    ;
  },
};

</script>
